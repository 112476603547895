import * as React from "react"

const Lunch3 = () => (
  <div className="text-xl">
    <p>
      <em className="text-2xl">109 kr</em>
    </p>
    <p>
      Onsdag - fredag <em>11:30 - 14:30</em>
    </p>

    <p>
      <b>Onsdag</b>
      <br />
      Ugnsgratinerad kassler med tomat- & gräddsås
      <br />
      Stekt torskryggfilé med ägg & persiljesås
      <br />
      Pasta med skaldjur & limesås
    </p>

    <p>
      <b>Torsdag</b>
      <br />
      Ugnsstekt kycklingbröst med gorgonzolasås
      <br />
      Halstrad laxfilé med dijonhollandaise
      <br />
      Pasta med parmaskinka & citronsås
    </p>

    <p>
      <b>Fredag</b>
      <br />
      Fläskfilé Black & White
      <br />
      Fiskgryta med skaldjur & hummerfond
      <br />
      Pasta med rökt skinka, grädde & parmesan
    </p>

    <p>
      <b>Veckans vegetariska</b>
      <br />
      Pasta med soltorkade tomater, mozzarella & basilka
    </p>

    <p>
      <em>inkl. sallad, hembakat bröd, dryck samt kaffe/te och kaka</em>
      <br />
      Avhämtning: 99 kr
    </p>
  </div>
)

export default Lunch3
